import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './custom.scss'
import 'font-awesome/css/font-awesome.min.css';
import CGU from './pages/CGU'; // Importer les nouvelles pages
import Confidentialite from './pages/Confidentialite';
import MaisonAstro from './pages/MaisonAstro';
import AstroGuide from './pages/AstroGuide';
import InterpretationSigne from './pages/InterpretationSigne';
import ConseilRelation from './pages/ConseilRelation';
import reportWebVitals from './reportWebVitals';
import Layout from './components/Layout';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <Router>
      <Layout>
        <Routes>
        {/* Route principale qui utilise App */}
        <Route path="/" element={<App />} />
        
        {/* Autres routes */}
        <Route path="/cgu" element={<CGU />} />
        <Route path="/politique-confidentialite" element={<Confidentialite />} />
        <Route path="/maison-astro" element={<MaisonAstro />} />
        <Route path="/astro-guide" element={<AstroGuide />} />
        <Route path="/interpretation-signe" element={<InterpretationSigne />} />
        <Route path="/conseil-relation" element={<ConseilRelation />} />
      </Routes>
      </Layout>
    </Router>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
