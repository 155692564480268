import React from 'react';

function Confidentialite() {
  return (
    <section className="pt-md-5 pt-lg-7 pb-md-5 pb-lg-7">
    <div className="container">
        <div className="col-lg-8 col-md-10 mx-auto">
            <div className="card shadow-lg">
                <div className="card-header bg-gradient-primary p-5 position-relative">
                    <h3 className="text-white mb-0">Politique de confidentialité</h3>
                    <p className="text-white opacity-8 mb-4">Dernière modification: 21 oct. 2024</p>
                    <div className="wave-page-container">
                        <svg className="waves-page svgStyle" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 40" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="astro-wave-page" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="moving-waves-page">
                                <use href="#astro-wave-page" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                <use href="#astro-wave-page" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                <use href="#astro-wave-page" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                <use href="#astro-wave-page" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                <use href="#astro-wave-page" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                <use href="#astro-wave-page" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="card-body p-5 pt-0">
                <br></br>
                <h4>ARTICLE 1.	PARTIES</h4>
                    <p>
                        La présente politique de confidentialité est applicable entre Pandaaz, SASU, capital social : 15000 €, RCS de Versailles : 837 958 065, siège social : 45 rue du Président Roosevelt, 78500 Sartrouville,
                        France, email : support@pandaaz.com, n° de TVA intracommunautaire : FR91837958065, ci-après dénommé « Pandaaz » et toute personne se connectant au site, ci-après « l’Utilisateur ».
                    </p>
                    <h4>ARTICLE 2.	DEFINITIONS</h4>
                    <p>
                        « Contenus du site» : éléments de toute nature publiés sur le Site, protégés ou non par un droit de propriété intellectuelle, tel que textes, images, designs, 
                        présentations, vidéos, schémas, structures, bases de données ou logiciels. <br></br> 
                        « Utilisateur » : toute personne, physique ou morale, de droit privé ou de droit public, naviguant sur le Site.<br></br> 
                        « Site » :  astrothemepro » édité par Pandaaz SASU, disponible via l'URL www.astrothemepro.com.<br></br> 
                    </p>
                    <h4>ARTICLE 3.	CHAMP D’APPLICATION</h4>
                    <p>
                        La présente politique de confidentialité est applicable à tout Utilisateur. La simple connexion et/ou navigation sur le Site  emportera acceptation pleine et 
                        entière de la présente politique de confidentialité. L'Utilisateur reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction.<br></br> 
                        L’Utilisateur reconnaît la valeur de preuve des systèmes d'enregistrement automatique de Pandaaz et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.<br></br> 
                        L'acceptation de la présente politique de confidentialité suppose de la part des Utilisateurs qu'ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation 
                        d'un tuteur ou d'un curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.<br></br> 
                    </p>
                    <h4>ARTICLE 4.	DONNEES PERSONNELLES</h4>
                    <h5>4.1.	Responsable du traitement</h5>
                    <p>
                        Le responsable du traitement est Pandaaz, SIREN : 837 958 065, siège social : 45 rue du Président Roosevelt, 78500 Sartrouville, France, email : support@pandaaz.com, n° de TVA intracommunautaire : FR91837958065.
                    </p>
                    <h5>4.2.	Collecte de données par Pandaaz</h5>
                    <h5>4.2.1.	Données collectées</h5>
                    <h5>4.2.1.1.	Données collectées lors de la navigation</h5>
                        Lors de la navigation sur l’Application, l’Utilisateur consent à ce que Pandaaz collecte les informations relatives à :<br></br> 
                        <ul>
                            <li> à son utilisation du site, </li>
                            <li> au contenu qu’il consulte et sur lequel il clique,</li>
                            <li> à l'appareil utilisé et à son environnement logiciel, </li>
                            <li> aux données de connexion (horaires, pages consultées, adresse IP…).</li>
                        </ul>
                    <h5>4.2.3.	Durée de conservation des données personnelles</h5>
                    <p>
                        Les données personnelles collectées lors de la navigation sont conservées pendant une durée raisonnable nécessaire à la bonne administration du Site et pour un maximum de 12 mois. 
                        Les données personnelles collectées lors de l'inscription sur le Site sont conservées pendant la durée de la relation contractuelle et jusqu’à 12 mois après 
                        la résiliation du contrat par l’Utilisateur.
                    </p>
                    <h5>4.2.4.	Sécurité et confidentialité des données personnelles</h5>
                    <p>
                        Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens actuels de la technique, dans le respect des dispositions du Règlement général sur la protection des données.
                    </p>
                    <h5>4.2.5.	Droit des personnes concerné</h5>

                        Conformément au Règlement général sur la protection des données, la personne concernée dispose des droits suivants : 
                        <ul>
                            <li>- le droit d’obtenir une copie des données personnelles la concernant détenues par Pandaaz. Avant de répondre à sa demande, Pandaaz est susceptible de (i) vérifier son identité et (ii) lui 
                            demander de fournir davantage d’informations pour répondre à sa demande;</li>
                            <li>- le droit de rectifier, modifier ou effacer toute information incorrecte, soit directement sur le compte en ligne, soit en adressant une demande à Pandaaz;</li>
                            <li>- le droit d’introduire une réclamation auprès de l’autorité de contrôle compétente ou d’obtenir réparation auprès des tribunaux compétents;</li>
                            <li>- le droit à la portabilité des données, c’est-à-dire le droit de recevoir les données personnelles la concernant dans un format structuré, couramment utilisé et 
                                lisible par la machine et le droit de transmettre ces données à un autre responsable du traitement.</li>
                            <li>- le droit de définir des directives relatives au sort de ses données personnelles après sa mort.</li>
                        </ul>
                            Pour exercer ses droits, la personne concernée doit en faire la demande à Pandaaz aux coordonnées mentionnées à l'article 1. Pandaaz s'efforcera de donner suite à la demande dans un délai 
                            raisonnable et, en tout état de cause, dans les délais fixés par la loi.

                    <h4>ARTICLE 5.	TRACEURS</h4>
                    <h5>5.1. Utilisation de traceurs</h5>
                    <p>
                        Pandaaz a recours à l’utilisation de cookies et autres traceurs. Les traceurs sont de petits fichiers texte, qui sont transférés d'un serveur vers le disque dur de 
                        l’Utilisateur. De cette façon, Pandaaz et ses partenaires obtiennent automatiquement des données spécifiques telles que l'adresse IP, le navigateur utilisé, le 
                        système d'exploitation sur l’ordinateur de l’Utilisateur et les informations relatives à la connexion et à la navigation de l’Utilisateur. 
                    </p>
                    <h5>5.2. Finalités des traceurs</h5>
                    <p>
                        Avec l'aide des informations contenues dans les traceurs, Pandaaz peut faciliter la navigation, réalisation des opérations de prospection, élaborer des statistiques commerciales ou mesurer l’audience du Site.
                    </p>
                    <h5>5.3. Traceurs utilisés</h5>
                    <p>
                        Partenaire | Finalité du traitement | Conditions du partenaire <br></br>
                        Heroku | Hébergement Cloud | https://www.salesforce.com/company/privacy/ 
                    </p> 
                    <h5>5.4. Durée de conservation maximale des traceurs</h5>
                    <p>
                        Les traceurs ont vocation à être conservés sur le poste informatique de l'Utilisateur pour une durée allant jusqu'à 13 mois. Ces données sont conservées dans des 
                        conditions sécurisées, selon les moyens actuels de la technique, dans le respect du Règlement général sur la protection des données. 
                    </p>
                    <h5>5.5. Opposition à l’utilisation de traceurs</h5>
                    <h5>5.5.1. Faculté d’opposition à l’utilisation de traceurs</h5>
                    <p>
                        L’Utilisateur peut supprimer ou désactiver l’utilisation des traceurs chaque fois qu’il le souhaite en modifiant les paramètres de son navigateur. Il est possible de 
                        consulter l’Application sans traceurs. Certaines fonctions annexes du Site peuvent toutefois ne pas fonctionner si l’Utilisateur a désactivé l’utilisation des traceurs, 
                        telles que l’autocomplétion des formulaires ou les indicateurs de navigation.
                    </p>
                    <h5>5.5.2 Paramétrages</h5>
                    <h5>5.5.2.1. Paramétrages du navigateur</h5>
                        L’Utilisateur peut configurer son logiciel de navigation de manière à ce que les cookies soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également 
                        configurer son logiciel de navigation de manière à ce que son acceptation ou son refus des cookies lui soit proposé ponctuellement, avant qu’un cookie ne soit susceptible d’être enregistré sur son terminal.<br></br> 
                        Pour la gestion des cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui 
                        permettra de savoir de quelle manière modifier ses souhaits en matière de cookies :
                        <ul>
                            <li>	<a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies" target="_blank" rel="noreferrer">Pour Internet Explorer</a></li>  
                            <li>	<a href="http://support.apple.com/fr-ch/HT1677" target="_blank" rel="noreferrer">Pour Safari </a></li>
                            <li>	<a href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647" target="_blank" rel="noreferrer">Pour Chrome </a></li>
                            <li>	<a href="http://support.mozilla.org/fr/kb/Activer et d%C3%A9sactiver les cookies" target="_blank" rel="noreferrer">Pour Firefox </a></li>
                            <li>	<a href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank" rel="noreferrer">Pour Opera</a>  </li>
                        </ul>
                        Si après un refus, L’Utilisateur souhaite de nouveau autoriser l’insertion de cookies sur son navigateur, il lui suffit de paramétrer celui-ci en ce sens.
                    <h5>5.5.2.2. Paramétrages sur un site tiers</h5>
                    <p>
                        L’Utilisateur peut également supprimer ou s’opposer à l’implantation de cookies sur son poste en se rendant à l’URL suivante : http://www.youronlinechoices.com/fr/controler-ses-cookies/
                    </p>
                    <h5>5.5.2.3. Paramétrages au moyen de modules complémentaires</h5>
                    <p>
                        L’Utilisateur peut également paramétrer la collecte de données personnelles en installant des modules complémentaires.
                    </p>
                    <h4>ARTICLE 7.	PROPRIETE INTELLECTUELLE</h4>
                    <h5>7.1. Protection légale des Contenus du Site</h5>
                    <p>
                        Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de données. Toute 
                        représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle, réalisée illégalement et sans le consentement de 
                        Pandaaz ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites judiciaires pour contrefaçon.
                    </p>
                    <h5>7.2. Protection contractuelle des Contenus du Site</h5>
                    <p>
                        Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de données. Toute 
                        représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle, réalisée illégalement et sans le consentement de 
                        Pandaaz ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites judiciaires pour contrefaçon.
                    </p>
                    <h4>ARTICLE 8.	STIPULATIONS FINALES</h4>
                    <h5>8.1. Modifications </h5>
                    <p>
                        La présente Politique de Confidentialité peut être modifiée à tout moment par Pandaaz. Les conditions applicables à l’Utilisateur sont celles en 
                        vigueur lors de sa connexion sur le Site, toute nouvelle connexion sur le Site emportant acceptation le cas échéant des nouvelles conditions.
                    </p>
                    <h5>8.2. Entièreté </h5>
                    <p>
                        La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou du contrat dans sa globalité, qui 
                        garderont leur plein effet et portée. Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la stipulation annulée par une stipulation 
                        valable correspondant à l'esprit et à l'objet des présentes.
                    </p>
                    <h5>8.3. Non-renonciation </h5>
                    <p>
                        L'absence d'exercice par Pandaaz des droits qui lui sont reconnus par les présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits droits.
                    </p>
                    <h5>8.4. Langues </h5>
                    <p>
                        Les présentes conditions sont proposées en français.
                    </p>
                    <h5>8.5. Clauses abusives </h5>
                    <p>
                        Les stipulations des présentes conditions s'appliquent sous réserve du respect des dispositions impératives du Code de la consommation concernant les clauses 
                        abusives dans les contrats conclus entre un professionnel et un consommateur.
                    </p>
                    <h4>ARTICLE 9.	LITIGES</h4>
                    <h5>9.1. Droit applicable </h5>
                    <p>
                        La présente Politique de Confidentialité est soumise à l'application du droit français.
                    </p>
                    <h5>9.2. Médiation </h5>
                    <p>
                        En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de l'exécution des présentes conditions 
                        générales et dont la solution n’aura pu être trouvée préalablement à l’amiable entre les parties devra être soumis à Medicys : www.medicys.fr.
                    </p>
                    <h5>9.3. Arbitrage </h5>
                    <p>
                        Si la procédure de médiation n’a pu aboutir à un accord amiable entre les parties, tout litige relatif au présent contrat ou en relation avec celui-ci 
                        sera tranché par voie d’arbitrage conformément au règlement de l’Institut digital d’arbitrage et de médiation : www.fast-arbitre.com.
                    </p>
                </div> 
            </div> 
        </div> 
    </div>
</section>


  );
}

export default Confidentialite;