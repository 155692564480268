import React from 'react';

function Footer() {
  return (
    <footer className="footer pt-5 mt-5">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <p className="my-4 text-sm">
            Tous droits réservés. Copyright © <script>
                            document.write(new Date().getFullYear())
                          </script>2024  by <a href="https://www.pandaaz.com" target="_blank" rel="noreferrer noopener">Pandaaz</a>.
            </p>
            <p className="my-2 text-sm">
              <a href="/cgu" className="text-muted">Conditions Générales d'Utilisation</a>&nbsp;|&nbsp; 
              <a href="/" className="text-muted">Accueil</a>&nbsp;|&nbsp; 
              <a href="/politique-confidentialite" className="text-muted"> Politique de Confidentialité</a>
          </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;