import React from 'react';
import Footer from './Footer';  // Assure-toi d'importer ton Footer
import Header from './Header';

function Layout({ children }) {
  return (
    <div className="main-container">
        <Header/>
            <main>{children}</main>
        <Footer />
    </div>
  );
}

export default Layout;