import React from 'react';

function Header() {
  return (
           <nav className="navbar navbar-expand-lg navbar-light bg-white py-3">
           <div className="container">
               <a className="navbar-brand font-weight-bold" href="/" rel="tooltip" title="Découvre ton théme astral" data-placement="bottom" >
                   Astro Thème PRO
               </a>
            
                   <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                       <span className="navbar-toggler-icon mt-2">
                       <span className="navbar-toggler-bar bar1"></span>
                       <span className="navbar-toggler-bar bar2"></span>
                       <span className="navbar-toggler-bar bar3"></span>
                       </span>
                   </button>
               <div className="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0" id="navigation">
                    <ul className="navbar-nav navbar-nav-hover mx-auto">
                       <li className="nav-item dropdown dropdown-hover mx-2">
                           <a className="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"  role="button" href="/maison-astro">
                               Maisons astrologiques
                           </a>
                       </li>
                       <li className="nav-item dropdown dropdown-hover mx-2">
                           <a className="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"  role="button" href="/astro-guide">
                               Base de l'astrologie
                           </a>
                       </li>
                       <li className="nav-item dropdown dropdown-hover mx-2">
                           <a className="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"  role="button" href="/interpretation-signe">
                               Interprétation des signes
                           </a>
                       </li>
                       <li className="nav-item dropdown dropdown-hover mx-2">
                           <a className="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"  role="button" href="/conseil-relation">
                               Conseils relationnels
                           </a>
                       </li>
                   </ul>
               </div>
            </div>
       </nav>
  );
}

export default Header;