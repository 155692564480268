import React from 'react';

function MaisonAstro() {
  return (
    <section className="pt-md-5 pt-lg-7 pb-md-5 pb-lg-7">
    <div className="container">
        <div className="col-lg-8 col-md-10 mx-auto">
            <div className="card shadow-lg">
                <div className="card-header bg-gradient-primary p-5 position-relative">
                    <h3 className="text-white mb-0">Les Maisons Astrologiques</h3>
                    <p className="text-white opacity-8 mb-4"></p>
                    <div className="wave-page-container">
                        <svg className="waves-page svgStyle" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 40" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="astro-wave-page" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="moving-waves-page">
                                <use href="#astro-wave-page" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                <use href="#astro-wave-page" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                <use href="#astro-wave-page" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                <use href="#astro-wave-page" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                <use href="#astro-wave-page" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                <use href="#astro-wave-page" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="card-body p-5 pt-0">
                <br></br>
                    <article>
                        <h3>Les Maisons Astrologiques : Comprendre Leur Importance</h3>
                        <p>L'astrologie est bien plus que la simple étude des signes du zodiaque. Les maisons astrologiques représentent un aspect essentiel de la carte du ciel, permettant d'analyser et de comprendre différents domaines de la vie d'une personne.</p>

                        <h4>Qu'est-ce que les Maisons Astrologiques ?</h4>
                        <p>Les maisons astrologiques sont au nombre de douze et représentent différents secteurs de la vie. Elles sont déterminées par l'heure et le lieu de naissance, et chacune correspond à un domaine particulier, comme la carrière, les relations, ou la santé. Ces maisons forment le cadre de l'analyse du thème astral, en montrant comment les énergies planétaires s'expriment dans la vie quotidienne.</p>

                        <h4>Description des Douze Maisons</h4>
                        <h5>1. La Première Maison : La Maison de l'Identité</h5>
                        <p>La première maison représente l'apparence physique, la personnalité et la manière dont une personne est perçue par les autres. Elle est souvent associée à l'ascendant et montre comment nous nous présentons au monde.</p>

                        <h5>2. La Deuxième Maison : La Maison des Ressources et des Biens</h5>
                        <p>Cette maison concerne les possessions matérielles, les finances, et la relation avec l'argent. Elle indique la façon dont une personne gagne et gère ses ressources.</p>

                        <h5>3. La Troisième Maison : La Maison de la Communication</h5>
                        <p>Elle représente la communication, l'intellect, et les relations avec l'entourage proche, tels que les frères et sœurs, ainsi que les déplacements courts.</p>

                        <h5>4. La Quatrième Maison : La Maison de la Famille et du Foyer</h5>
                        <p>Cette maison symbolise le foyer, la famille, les racines et l'héritage familial. Elle est liée à la sécurité émotionnelle et aux influences parentales, en particulier la mère.</p>

                        <h5>5. La Cinquième Maison : La Maison de la Créativité et des Plaisirs</h5>
                        <p>La cinquième maison est associée aux plaisirs, à la créativité, aux enfants et aux amours. Elle montre comment nous nous exprimons de manière ludique et artistique.</p>

                        <h5>6. La Sixième Maison : La Maison du Travail et de la Santé</h5>
                        <p>Elle représente le quotidien, la santé, le service aux autres et le travail au quotidien. C'est une maison de l'ordre et de la discipline.</p>

                        <h5>7. La Septième Maison : La Maison des Partenariats</h5>
                        <p>La septième maison est celle des partenariats, qu'ils soient amoureux ou professionnels. Elle montre comment une personne se comporte dans les relations à long terme et les associations.</p>

                        <h5>8. La Huitième Maison : La Maison de la Transformation et de la Mort</h5>
                        <p>Cette maison est liée aux transformations profondes, aux ressources partagées, aux héritages et à la sexualité. Elle symbolise la renaissance et le pouvoir de la régénération.</p>

                        <h5>9. La Neuvième Maison : La Maison des Voyages et de la Philosophie</h5>
                        <p>La neuvième maison est associée aux voyages, à l'enseignement supérieur, à la philosophie et aux croyances. Elle représente la quête de sens et l'expansion de l'esprit.</p>

                        <h5>10. La Dixième Maison : La Maison de la Carrière et de la Réputation</h5>
                        <p>La dixième maison montre la carrière, la réputation, et l'image publique. Elle est souvent liée aux objectifs professionnels et à la manière dont une personne est perçue dans le monde.</p>

                        <h5>11. La Onzième Maison : La Maison des Amis et des Projets Collectifs</h5>
                        <p>Elle représente les amitiés, les groupes, les aspirations collectives et les projets. Cette maison montre comment une personne s'intègre dans un réseau social et ses contributions à la société.</p>

                        <h5>12. La Douzième Maison : La Maison de l'Inconscient et de la Spiritualité</h5>
                        <p>La douzième maison est mystérieuse et représente l'inconscient, la spiritualité, les rêves et les épreuves. C'est une maison d'introspection et de connexion spirituelle.</p>

                        <h4>Conclusion</h4>
                        <p>Les maisons astrologiques sont un pilier fondamental pour comprendre le thème astral et l'influence des astres dans les divers aspects de la vie. Leur analyse détaillée peut révéler des éléments profonds et essentiels sur la manière dont une personne interagit avec le monde qui l'entoure.</p>
                    </article>
                </div> 
            </div> 
        </div> 
    </div>
</section> 
);
}

export default MaisonAstro;