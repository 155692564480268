import React from 'react';

function AstroGuide() {
  return (
    <section className="pt-md-5 pt-lg-7 pb-md-5 pb-lg-7">
    <div className="container">
        <div className="col-lg-8 col-md-10 mx-auto">
            <div className="card shadow-lg">
                <div className="card-header bg-gradient-primary p-5 position-relative">
                    <h3 className="text-white mb-0">Les Bases de l'Astrologie</h3>
                    <p className="text-white opacity-8 mb-4"></p>
                    <div className="wave-page-container">
                        <svg className="waves-page svgStyle" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 40" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="astro-wave-page" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="moving-waves-page">
                                <use href="#astro-wave-page" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                <use href="#astro-wave-page" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                <use href="#astro-wave-page" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                <use href="#astro-wave-page" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                <use href="#astro-wave-page" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                <use href="#astro-wave-page" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="card-body p-5 pt-0">
                <br></br>
                    <article>
                        <h3>Les Bases de l'Astrologie : Un Guide Complet</h3>
                        <p>L'astrologie est un art ancien qui étudie la position et les mouvements des corps célestes pour comprendre leur influence sur les événements terrestres et les personnalités humaines. Bien plus qu'une simple lecture de l'horoscope, l'astrologie est une science complexe qui nécessite une compréhension approfondie de ses éléments fondamentaux. Dans cet article, nous allons explorer les bases essentielles de l'astrologie.</p>

                        <h4>1. Les Signes du Zodiaque</h4>
                        <p>Le zodiaque est divisé en douze signes, chacun représentant un archétype unique. Chaque signe est associé à un élément (feu, terre, air, eau) et à une qualité (cardinale, fixe, mutable), définissant la manière dont il interagit avec le monde.</p>

                        <h5>Les Douze Signes et Leurs Caractéristiques</h5>
                        <ul>
                            <li><strong>Bélier (21 mars - 19 avril)</strong> : Premier signe du zodiaque, le Bélier est associé à l'énergie, l'initiative et l'audace. Il est régi par Mars et représente l'élément feu.</li>
                            <li><strong>Taureau (20 avril - 20 mai)</strong> : Signe de terre, le Taureau est connu pour sa stabilité, sa patience et son amour du confort. Il est gouverné par Vénus.</li>
                            <li><strong>Gémeaux (21 mai - 20 juin)</strong> : Signe d'air, les Gémeaux sont adaptables, curieux et communicatifs. Ils sont régis par Mercure.</li>
                            <li><strong>Cancer (21 juin - 22 juillet)</strong> : Signe d'eau, le Cancer est émotif, protecteur et attaché à la famille. La Lune est sa planète gouvernante.</li>
                            <li><strong>Lion (23 juillet - 22 août)</strong> : Signe de feu, le Lion est fier, généreux et aime être au centre de l'attention. Il est régi par le Soleil.</li>
                            <li><strong>Vierge (23 août - 22 septembre)</strong> : Signe de terre, la Vierge est analytique, pratique et perfectionniste. Mercure est sa planète maîtresse.</li>
                            <li><strong>Balance (23 septembre - 22 octobre)</strong> : Signe d'air, la Balance valorise l'harmonie, l'équilibre et les relations. Elle est gouvernée par Vénus.</li>
                            <li><strong>Scorpion (23 octobre - 21 novembre)</strong> : Signe d'eau, le Scorpion est intense, passionné et mystérieux. Il est gouverné par Pluton et Mars.</li>
                            <li><strong>Sagittaire (22 novembre - 21 décembre)</strong> : Signe de feu, le Sagittaire est optimiste, aventurier et philosophe. Il est régi par Jupiter.</li>
                            <li><strong>Capricorne (22 décembre - 19 janvier)</strong> : Signe de terre, le Capricorne est ambitieux, discipliné et responsable. Il est gouverné par Saturne.</li>
                            <li><strong>Verseau (20 janvier - 18 février)</strong> : Signe d'air, le Verseau est innovant, humanitaire et indépendant. Il est régi par Uranus et Saturne.</li>
                            <li><strong>Poissons (19 février - 20 mars)</strong> : Signe d'eau, les Poissons sont rêveurs, empathiques et spirituels. Ils sont gouvernés par Neptune et Jupiter.</li>
                        </ul>

                        <h4>2. Les Éléments et Leurs Significations</h4>
                        <p>Les douze signes du zodiaque se répartissent en quatre éléments, chacun représentant une énergie fondamentale qui influence la manière dont les signes se comportent.</p>

                        <h5>Les Quatre Éléments</h5>
                        <ul>
                            <li><strong>Feu (Bélier, Lion, Sagittaire)</strong> : Représente l'énergie, l'enthousiasme et la passion. Les signes de feu sont dynamiques et impulsifs.</li>
                            <li><strong>Terre (Taureau, Vierge, Capricorne)</strong> : Symbolise la stabilité, la praticité et la matérialité. Les signes de terre sont réalistes et ancrés.</li>
                            <li><strong>Air (Gémeaux, Balance, Verseau)</strong> : Incarne l'intellect, la communication et la sociabilité. Les signes d'air sont analytiques et orientés vers l'échange.</li>
                            <li><strong>Eau (Cancer, Scorpion, Poissons)</strong> : Représente l'émotion, l'intuition et la profondeur. Les signes d'eau sont sensibles et connectés aux sentiments.</li>
                        </ul>

                        <h4>3. Les Planètes et Leur Influence</h4>
                        <p>Les planètes jouent un rôle essentiel en astrologie. Chaque planète représente un type spécifique d'énergie qui affecte différents aspects de la vie.</p>

                        <h5>Les Planètes Personnelles</h5>
                        <ul>
                            <li><strong>Le Soleil</strong> : Représente l'identité, l'ego et la volonté. Il montre comment une personne brille et s'exprime.</li>
                            <li><strong>La Lune</strong> : Associée aux émotions, à l'intuition et aux besoins subconscients.</li>
                            <li><strong>Mercure</strong> : Symbolise l'intellect, la communication et les processus mentaux.</li>
                            <li><strong>Vénus</strong> : Représente l'amour, la beauté et les relations.</li>
                            <li><strong>Mars</strong> : Associé à l'action, la passion et la détermination.</li>
                        </ul>

                        <h5>Les Planètes Collectives</h5>
                        <ul>
                            <li><strong>Jupiter</strong> : Planète de l'expansion, de la croissance et de la chance.</li>
                            <li><strong>Saturne</strong> : Représente la discipline, la responsabilité et les leçons de vie.</li>
                        </ul>

                        <h5>Les Planètes Transpersonnelles</h5>
                        <ul>
                            <li><strong>Uranus</strong> : Symbolise le changement, l'innovation et la rébellion.</li>
                            <li><strong>Neptune</strong> : Associée aux rêves, à la spiritualité et à l'imagination.</li>
                            <li><strong>Pluton</strong> : Représente la transformation, le pouvoir et la renaissance.</li>
                        </ul>

                        <h4><a href="/maison-astro">4. Les Maisons Astrologiques</a></h4>
                        <p>En plus des signes et des planètes, les maisons astrologiques apportent des informations sur les domaines spécifiques de la vie d'une personne. Chaque maison est associée à un aspect particulier de l'existence, comme la famille, la carrière ou les relations.</p>

                        <h4>5. Les Aspects Planétaires</h4>
                        <p>Les aspects sont les angles formés entre les planètes dans le ciel. Ils montrent comment les différentes énergies planétaires interagissent entre elles.</p>

                        <h5>Les Aspects Majeurs</h5>
                        <ul>
                            <li><strong>Conjonction</strong> (0°) : Deux planètes sont proches l'une de l'autre, amplifiant leur énergie.</li>
                            <li><strong>Opposition</strong> (180°) : Les planètes sont en face l'une de l'autre, créant des tensions à équilibrer.</li>
                            <li><strong>Trine</strong> (120°) : Aspect harmonieux qui facilite le flux d'énergie entre les planètes.</li>
                            <li><strong>Carré</strong> (90°) : Un aspect qui crée des défis et des conflits.</li>
                            <li><strong>Sextile</strong> (60°) : Aspect favorable et stimulant, mais moins puissant que le trine.</li>
                        </ul>

                        <h4>Conclusion</h4>
                        <p>Les bases de l'astrologie englobent la compréhension des signes du zodiaque, des éléments, des planètes, des maisons et des aspects. Ensemble, ces éléments forment un système complexe qui peut aider à comprendre l'influence des astres sur la vie humaine. En explorant et en étudiant ces fondements, chacun peut enrichir sa connaissance de soi et des dynamiques qui façonnent son existence.</p>
                    </article>
                </div> 
            </div> 
        </div> 
    </div>
</section> 
);
}

export default AstroGuide;