import React from 'react';

function CGU() {
  return (
    <section className="pt-md-5 pt-lg-7 pb-md-5 pb-lg-7">
    <div className="container">
        <div className="col-lg-8 col-md-10 mx-auto">
            <div className="card shadow-lg">
                <div className="card-header bg-gradient-primary p-5 position-relative">
                    <h3 className="text-white mb-0">Conditions générales d'utilisation </h3>
                    <p className="text-white opacity-8 mb-4">applicables à compter du 21 oct. 2024</p>
                    <div className="wave-page-container">
                        <svg className="waves-page svgStyle" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 40" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="astro-wave-page" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="moving-waves-page">
                                <use href="#astro-wave-page" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                <use href="#astro-wave-page" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                <use href="#astro-wave-page" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                <use href="#astro-wave-page" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                <use href="#astro-wave-page" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                <use href="#astro-wave-page" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="card-body p-5 pt-0">
                <br></br>
                <h4>ARTICLE 1.	MENTIONS LEGALES</h4>
                    <p>
                    Vous utilisez actuellement le Site astrothemepro.com, éditée par Pandaaz, SASU, capital social : 15000 €, RCS de Versailles : 837 958 065, siège social : 45 rue du Président Roosevelt, 78500 Sartrouville, France, email : support@pandaaz.com,
                    n° de TVA intracommunautaire : FR91837958065.

                    </p>

                    
                    <h4>ARTICLE 2.	DEFINITIONS</h4>
                    <p>
                        <b>« Appareil »</b> : outil de télécommunication, notamment smartphone ou tablette, dont les caractéristiques physiques et logicielles permettent de télécharger et d’utiliser l’Application.<br></br>
                        <b>« Site » </b>: « astrathemepro.com » édité par Pandaaz SASU, disponible en téléchargement depuis le Store et utilisable sur les Appareils.<br></br>
                        <b>« Donnée » : </b>contenu de toute nature publié par un Utilisateur sur le Site.<br></br>
                        <b>« Option » : </b>améliorations du Site pouvant être achetés directement sur le Site.<br></br>
                        <b>« PANDAAZ » : </b>Pandaaz SASU, prise en sa qualité d’éditeur du Site.<br></br>
                        <b>« Service » : </b>prestation proposée par PANDAAZ et permettant notamment aux Utilisateurs de créer, envoyer et/ou consulter des Données. <br></br>
                        <b>« Utilisateur » </b>: toute personne, physique ou morale, de droit privé ou de droit public, naviguant sur le Site.<br></br>

                    </p>
                    
                    <h4>ARTICLE 3.	CHAMP D’APPLICATION</h4>
                    <p>
                        Le site est d'accès libre et gratuit à tout Utilisateur. <br></br>
                        L’accès au Site suppose l'acceptation par l’Utilisateur des présentes conditions générales. Lors de l'inscription sur le site, cette acceptation 
                        sera confirmée par le fait de cocher la case correspondant à la phrase suivante : « Je reconnais avoir lu et accepté les conditions générales d’utilisation ». 
                        L'Utilisateur reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction.<br></br>
                        Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part de l’Utilisateur. L'Utilisateur reconnaît la valeur de preuve des systèmes
                        d'enregistrement automatique de PANDAAZ et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.<br></br>
                        Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes autres conditions, et notamment celles de l’Utilisateur. <br></br>
                        L'acceptation des présentes conditions générales suppose de la part des Utilisateurs qu'ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu'ils en 
                        aient l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.<br></br>
                    </p>

                    <h4>ARTICLE 4.	OBJET DU SITE</h4>
                    <p>
                        Le site a pour objet de fournir à l'utilisateur un théme astral personalisé sur la base des informations qu'il aura communiqué.<br></br>
                    </p>
                    
                    <h4>ARTICLE 5.	DESCRIPTION DES SERVICES</h4>
                    <h5>5.1.	Connexion à l’Application</h5>
                    <p>
                        La création d'un espace personnel est possible mais pas indispensable à toute connexion au Site. A cette fin, l’Utilisateur sera invité à fournir un certain nombre d'informations personnelles. 
                        Certaines de ces informations sont réputées indispensables à la création de l'espace personnel. Le refus par un Utilisateur de fournir lesdites informations aura pour effet d'empêcher la création de l'espace personnel.<br></br>
                        Lors de la création de l'espace personnel, l'Utilisateur est invité à personnaliser son mot de passe. Ce mot de passe constitue la garantie de la confidentialité des 
                        informations contenues dans l’espace personnel. L'Utilisateur s'interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, PANDAAZ ne pourra être tenu pour responsable des accès non autorisés à l’espace personnel d'un Utilisateur.<br></br>
                        L'Utilisateur s’engage à procéder à une vérification régulière des données qui le concernent et à procéder en ligne, depuis son espace personnel, aux actualisations et modifications nécessaires.<br></br>

                    </p>
                    <h5>5.2.	Espace personnel</h5>
                    <h5>5.2.1.	Suppression de l’espace personnel</h5>
                    <p>
                        PANDAAZ se réserve le droit de supprimer le compte de tout Utilisateur qui contrevient aux présentes conditions générales, notamment lorsque l'Utilisateur fournit des informations inexactes, incomplètes, 
                        mensongères ou frauduleuses, ainsi que lorsque l’espace personnel d’un Utilisateur sera resté inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer une faute de PANDAAZ 
                        ou un dommage pour l'Utilisateur exclu, qui ne pourra prétendre à aucune indemnité de ce fait.<br></br>
                        Cette exclusion est sans préjudice de la possibilité, pour PANDAAZ, d'entreprendre des poursuites d'ordre judiciaire à l'encontre de l'Utilisateur, lorsque les faits l'auront justifié.<br></br>
                    </p>
                    <h5>5.3.	Utilisation du Service par l’Utilisateur</h5>
                    <h5>5.4.1.	Contributions des Utilisateurs</h5>
                   
                        Dans le cadre de la publication de Données, l’Utilisateur s’engage à ne pas :
                        <ul>
                            <li> porter atteinte ou avoir de propos contraires à l'ordre public, aux bonnes mœurs ou pouvant heurter la sensibilité des mineurs ;</li>
                            <li> porter atteinte de quelque manière que ce soit aux droits à la réputation, à la vie privée, au droit ou à l'image d’un tiers ;</li>
                            <li> être dénigrant, diffamatoire, porter atteinte à l'image, à la réputation d'une marque ou d'une quelconque personne physique ou morale, de quelque manière que ce soit ;</li>
                            <li> avoir de propos à caractère pornographique ou pédophile ;</li>
                            <li> diffuser du contenu à caractère pornographique ou pédophile; </li>
                            <li> porter atteinte à la sécurité ou à l'intégrité d'un Etat ou d'un territoire, quel qu'il soit ;</li>
                            <li> permettre à des tiers de se procurer des logiciels piratés, des numéros de série de logiciels ou tout logiciel pouvant nuire ou porter atteinte, de quelque manière que ce soit, aux droits ou aux biens des tiers ;</li>
                            <li> porter atteinte aux droits de propriété intellectuelle de quelque personne que ce soit ;</li>
                            <li> inciter à la haine, à la violence, au suicide, au racisme, à l'antisémitisme, à la xénophobie, à l'homophobie, faire l'apologie des crimes de guerre ou des crimes contre l'humanité ;</li>
                            <li> inciter à commettre un crime, un délit ou un acte de terrorisme ;</li>
                            <li> inciter à la discrimination d'une personne ou d'un groupe de personnes en raison de son appartenance à une ethnie, à une religion, à une race, ou du fait de son orientation sexuelle ou de son handicap.</li>
                            <li> conseiller une pratique douteuse ou frauduleuse ;</li>
                            <li> inscrire de lien hypertexte ou faire la publicité ou la promotion d'une société, d'une marque, d'un site, d'un blog ou d'un forum ;</li>
                            <li> communiquer directement ses coordonnées personnelles aux autres Utilisateurs ;</li>
                            <li> utiliser le Site à des fins professionnelles ou commerciales. </li>
                        </ul>
                        Tout usage illégal ou de manière générale non autorisé du Service entraînera la suppression immédiate du compte de l’Utilisateur, nonobstant pour PANDAAZ toute possibilité d’action en justice, notamment demande de dommages et intérêts. 
                   
                    <h5>5.4.2.	Déclarations de l’Utilisateur</h5>
                    <p>
                        L’Utilisateur assure avoir pris connaissance, préalablement à la signature des présentes, des caractéristiques techniques de l’Application.<br></br>
                        En conséquence, l’Utilisateur reconnaît que ses besoins et les Services proposés par PANDAAZ sont en adéquation, qu’il a connaissance du contenu et des conditions d’exécution desdits Services 
                        et qu’il a souscrit aux prestations fournies par PANDAAZ en connaissance de cause et en disposant de toutes les informations nécessaires lui permettant de produire un consentement libre et éclairé.<br></br>
                    </p>
                    <h5>5.4.3.	Utilisation du Site </h5>
                    <p>
                        Tout traitement, transmission, publication, diffusion ou représentation du Site par l’Utilisateur sont effectués sous sa seule et entière responsabilité. 
                        L’Utilisateur s'engage, en particulier, à ne traiter, diffuser, télécharger, ou transmettre par l'intermédiaire du Site que des Données dont l'exploitation ne viole aucun droit de propriété intellectuelle 
                        ou industrielle ni tout autre droit privatif, ou ne constitue pas la commission d'une infraction pénale.<br></br>
                        L’Utilisateur s'engage également à ne pas entraver ou perturber le Site et à se conformer aux conditions requises, aux procédures, aux règles générales qui lui sont communiquées par PANDAAZ pour la bonne mise en œuvre de l’Application.<br></br> 
                        L’Utilisateur garantit en conséquence PANDAAZ contre tout dommage susceptible de lui être causé du fait de son utilisation de l’Application, en ce compris 
                        les éventuels frais d’avocat et de procédure, et s’engage à ce titre à intervenir à toute instance judiciaire engagée à son encontre du fait de son utilisation de l’Application.<br></br>
                    </p>
                    <h5>5.4.4.	Utilisation de l’Application</h5>
                    <p>
                        L’utilisation du Site est gratuite. Toutefois, les Utilisateurs ont la possibilité d’acquérir des Options depuis le Site. 
                        L’acquisition des ces Options n’est pas nécessaire à l’utilisation de l’Application mais permet d’accéder à certaines fonctionnalités et options spécifiques.<br></br>
                        Les fonctionnalités et options spécifiques sont celles mentionnées sur le Site au jour de la commande de l’Utilisateur.<br></br>
                    </p>
                    <h4>ARTICLE 6.	PRIX - PAIEMENT</h4>
                    <h5>6.1.	Prix des Options</h5>
                    <p>
                        Les prix applicables sont ceux affichés au sein du Site au jour de la commande. Les prix affichés ne sont valables qu'au jour de la commande et ne portent pas effet pour l'avenir.<br></br>
                    </p>
                    <h5>6.2.	Modalités de paiement</h5>
                    <p>
                        L’Utilisateur effectue son règlement via les moyens de paiements mis à sa disposition Paypal ou Stripe.<br></br>
                        PANDAAZ n'a accès à aucune donnée relative aux moyens de paiement de l’Utilisateur. 
                    </p>
                    
                    <h4>ARTICLE 7.	SERVICE CLIENTELE</h4>
                    <p>
                        Le service clientèle de l’Application est accessible, par courrier électronique à : support@pandaaz.com ou par courrier postal à l’adresse indiquée à l’article 1 des présentes conditions générales. 
                        Dans ces deux derniers cas, PANDAAZ s'engage à apporter une réponse sous 7 jours ouvrés.
                    </p>
                    
                    <h4>ARTICLE 8.	ABSENCE DE DROIT DE RETRACTATION</h4>
                    <p>
                        Le droit de rétractation ne s’applique pas, notamment, aux contrats de fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution 
                        a commencé après accord préalable exprès de l’Utilisateur et renoncement exprès à son droit de rétractation.<br></br>
                        De même, le droit de rétractation n’est pas applicable aux contrats exécutés intégralement par les deux parties à la demande expresse de l’Utilisateur avant que ce dernier n'exerce son droit de rétractation.<br></br>
                        Lors de la validation de la commande d’un contenu numérique indépendant de tout support matériel avant l'expiration du délai de rétractation, le renoncement de l’Utilisateur au droit de rétractation sera manifesté par 
                        le fait de cocher la case correspondant à la phrase suivante : « Je renonce expressément à mon droit de rétractation de 14 jours pour les Produits livrés ». L’Utilisateur recevra alors une confirmation par 
                        email de son renoncement au droit de rétractation.<br></br>
                        L’Utilisateur qui a exercé son droit de rétractation d'un contrat de prestation de services dont l'exécution a commencé, à sa demande expresse, avant la fin du délai de rétractation verse 
                        au professionnel un montant correspondant au service fourni jusqu'à la communication de sa décision de se rétracter, ce montant étant proportionnel au prix total de la prestation convenu dans le contrat.<br></br>
                    </p>

                    <h4>ARTICLE 9.	RESPONSABILITE DE PANDAAZ</h4>
                    <h5>9.1.	Nature des obligations de PANDAAZ</h5>
                    <h5>9.1.1.	Fourniture du Site</h5>
                    <p>
                        PANDAAZ s'engage à apporter le soin et la diligence nécessaire à la fourniture de Services de qualité conforme aux spécifications des présentes Conditions générales. 
                        PANDAAZ ne répond que d'une obligation de moyens concernant les services objet des présentes.<br></br>
                        PANDAAZ n’agit dans le cadre des présentes en qualité de simple prestataire technique. PANDAAZ ne joue aucun rôle actif lui permettant d’avoir une connaissance ou un contrôle des Données transmises ou stockées. 
                        PANDAAZ ne répond que d'une obligation de moyen concernant le Service objet des présentes. PANDAAZ ne garantit pas les rencontres entre les Utilisateurs. PANDAAZ ne s’engage en aucun cas à organiser des rencontres entre les Utilisateurs.
                    </p>
                    <h5>9.1.2.	Hébergeur des Données</h5>
                    <p>
                        PANDAAZ est hébergeur des Données, au sens de l'article 6-I-2 de la Loi pour la confiance dans l'économie numérique du 21 juin 2004. Conformément à l'alinéa 3 du 
                        même article, PANDAAZ ne pourra voir sa responsabilité civile ou pénale engagée à raison des Données, à moins que, dès le moment où il a eu connaissance de l'activité ou de l'information illicite, il n'ait agi 
                        promptement pour retirer ces informations ou en rendre l'accès impossible.<br></br> 
                        Les Données publiées hébergées pourront faire l'objet d'une modération a posteriori, sans que cette modération ne puisse constituer une faute de PANDAAZ ou un préjudice pour l’Utilisateur. 
                        Afin de mettre en œuvre cette modération, tout Utilisateur est invité à prendre contact avec PANDAAZ, afin de signaler un contenu abusif, contraire à l'ordre public, aux bonnes mœurs, ou à 
                        toute autre disposition législative ou réglementaire en vigueur. 
                    </p>
                    <h5>9.2.	Force majeure - Faute de l’Utilisateur</h5>
                    <p>
                        PANDAAZ n'engagera pas sa responsabilité en cas de force majeure ou de faute de l’Utilisateur, telles que définies au présent article :
                    </p>
                    <h5>9.1.3.	Force majeure</h5>
                    <p>
                        Au sens des présentes conditions générales, sera considéré comme un cas de force majeure opposable à l’Utilisateur tout empêchement, limitation ou dérangement du Service 
                        du fait d'incendie, d'épidémie, d'explosion, de tremblement de terre, de fluctuations de la bande passante, de manquement imputable au fournisseur d'accès, de défaillance des réseaux de transmission, d'effondrement des 
                        installations, d'utilisation illicite ou frauduleuse des mots de passe, codes ou références fournis à l’Utilisateur, de piratage informatique, d'une faille de sécurité imputable à l'hébergeur de l’Application ou aux 
                        développeurs, d'inondation, de panne d'électricité, de guerre, d'embargo, de loi, d'injonction, de demande ou d'exigence de tout gouvernement, de réquisition, de grève, de boycott, ou autres circonstances 
                        hors du contrôle raisonnable d’PANDAAZ. Dans de telles circonstances, PANDAAZ sera dispensé de l'exécution de ses obligations dans la limite de cet empêchement, de cette limitation ou de ce dérangement.
                    </p>
                    <h5>9.1.4.	Faute de l’Utilisateur</h5>
                    <p>
                        Au sens des présentes Conditions générales, sera considéré comme une faute de l’Utilisateur opposable à ce dernier toute mauvaise utilisation du Service, faute, négligence, omission 
                        ou défaillance de sa part ou de celle de ses préposés, non-respect des conseils donnés par PANDAAZ sur son Application, toute divulgation ou utilisation illicite du mot de passe, des 
                        codes et références de l’Utilisateur, ainsi que le renseignement d’informations erronées ou l’absence de mise à jour de telles informations dans son espace personnel. Sera également considérée 
                        comme une faute de l’Utilisateur la mise en œuvre de tout procédé technique, tels que des robots, ou des requêtes automatiques, dont la mise en œuvre contreviendrait à la lettre ou à l’esprit 
                        des présentes conditions générales de vente.
                    </p>
                    <h5>9.3.	Problèmes techniques - Liens hypertextes</h5>
                    <p>
                        En cas d'impossibilité d'accès au Site, en raison de problèmes techniques de toutes natures, l’Utilisateur ne pourra se prévaloir d'un dommage et ne pourra prétendre à aucune indemnité. L'indisponibilité, 
                        même prolongée et sans aucune durée limitative, d'un ou plusieurs services en ligne, ne peut être constitutive d'un préjudice pour les Utilisateurs et ne peut aucunement donner lieu à l'octroi de dommages et intérêts de la part d’PANDAAZ.<br></br>
                        Les liens hypertextes présents sur le Site peuvent renvoyer sur d'autres Applications et/ou sites internet. La responsabilité d’PANDAAZ ne saurait être engagée si le contenu de ces Applications 
                        contrevient aux législations en vigueur. De même la responsabilité d’PANDAAZ ne saurait être engagée si la visite, par l'Utilisateur, de l'un de ces Applications et/ou site internet, lui causait un préjudice. 
                    </p>
                    <h5>9.4.	Dommages-intérêts à la charge de PANDAAZ</h5>
                    <p>
                        A défaut de dispositions légales ou réglementaires contraires, la responsabilité de PANDAAZ est limitée au préjudice direct, personnel et certain subi par l’Utilisateur et 
                        lié à la défaillance en cause. PANDAAZ ne pourra en aucun cas être tenu responsable des dommages indirects tels que, notamment les pertes de données, les préjudices commerciaux, 
                        les pertes de commandes, les atteintes à l'image de marque, les troubles commerciaux et les pertes de bénéfices ou de clients.
                    </p>
                    <h5>9.5.	Liens hypertextes et contenus du Site</h5>
                    <p>
                        Les Contenus du Site sont publiés à titre indicatif, sans garantie d'exactitude. PANDAAZ ne peut en aucun cas être tenu responsable du fait d'une omission, d'une inexactitude
                        ou de toute erreur contenue dans ces informations et qui serait à l'origine d'un dommage direct ou indirect causé à l'Utilisateur.
                    </p>
                    <h4>ARTICLE 10.	PROPRIETE INTELLECTUELLE</h4>
                    
                        PANDAAZ propose à l’Utilisateur de navigeur sur le Site. L'utilisation du Site est gratuite. Dans ce cadre, PANDAAZ consent à l’Utilisateur, à titre non exclusif, une 
                        licence d’utilisateur final portant sur le Site. La licence est consentie pour le monde entier et pour toutes les langues. La licence porte uniquement sur le droit d’utilisation du Site, à l’exclusion de :<br></br>
                        <ul>
                            <li> toute reproduction en vue de la commercialisation, de la mise en circulation, de la rediffusion, de la distribution, de la publication de l’Application ou de l’installation de L’Application sur un autre poste que ceux de l’Utilisateur ;</li>
                            <li> toute représentation, même partielle, du Site, notamment par voie de communications au public en ligne ou de projections publiques ;</li>
                            <li> toute traduction, adaptation, d’arrangement ou de modification du Site, ainsi que tout export ou fusion de ce dernier avec d’autres Sites informatiques.</li>
                            <li> toute compilation, décompilation, désassemblage, traduction, analyse, reverse engineering du Site.</li>
                        </ul>
                        Les droits d’utilisation du Site sont personnels et non cessibles. Les droits accordés à l’Utilisateur le sont exclusivement pour un utilisateur unique, pour ses besoins 
                        propres, à l’exclusion de toute forme de fourniture à des tiers, même à une société filiale ou un membre d’un groupement de droit ou de fait. L’Utilisateur ne pourra céder 
                        tout ou partie des droits et obligations résultant du présent contrat, que ce soit dans le cadre de l’existence matérielle des droits objets de la présente licence.
                    
                    <h4>ARTICLE 11.	STIPULATIONS FINALES</h4>
                    <h5>11.1.	Droit applicable</h5>
                    <p>
                        Les présentes conditions générales sont soumises à l'application du droit français.
                    </p>
                    <h5>11.2.	Modifications des présentes conditions générales</h5>
                    <p>
                        Les présentes conditions générales peuvent être modifiées à tout moment par PANDAAZ. Les conditions générales applicables à l’Utilisateur sont celles en vigueur au jour de sa 
                        commande ou de sa connexion sur le présent Site, toute nouvelle connexion à l'espace personnel emportant acceptation le cas échéant des nouvelles conditions générales.
                    </p>
                    <h5>11.3.	Litiges</h5>
                    <p>
                        En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de l'exécution des présentes conditions générales et dont la solution 
                        n’aura pu être trouvée préalablement à l’amiable entre les parties devra être soumis à Medicys : www.medicys.fr.<br></br>
                        Tout litige relatif au présent contrat ou en relation avec celui-ci sera tranché par voie d’arbitrage conformément au règlement de l’Institut digital d’arbitrage et de médiation : www.fast-arbitre.com.
                    </p>
                    <h5>11.4.	Entièreté</h5>
                    <p>
                        La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle 
                        hypothèse, les parties devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant à l'esprit et à l'objet des présentes.
                    </p>
                    <h5>11.5.	Non-renonciation</h5>
                    <p>
                        L'absence d'exercice par PANDAAZ des droits qui lui sont reconnus par les présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits droits.
                    </p>
                    <h5>11.6.	Démarchage téléphonique</h5>
                    <p>
                        L’Utilisateur est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage téléphonique à l’adresse http://www.bloctel.gouv.fr/. 
                    </p>
                    <h5>11.7.	Langues des présentes conditions générales</h5>
                    <p>
                        Les présentes conditions générales sont proposées en français.
                    </p>
                    <h5>11.7.	Clauses abusives</h5>
                    <p>
                        Les stipulations des présentes conditions générales s'appliquent sous réserve du respect des dispositions impératives du Code de la consommation concernant les clauses abusives dans les contrats 
                        conclus entre un professionnel et un consommateur.
                    </p>
                </div> 
            </div> 
        </div> 
    </div>
</section>


  );
}

export default CGU;