import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { marked } from 'marked';
import io from 'socket.io-client'; // Importer socket.io-client

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

//import GeocoderGeonames from 'geocoder-geonames';

function App() {
  const [formData, setFormData] = useState({
    prenom: '',
    dateNaissance: '',
    heureNaissance: '',
    villeNaissance: '',
    paysNaissance: ''
  });
  const [suggestions, setSuggestions] = useState([]); // Suggestions d'autocomplétion
  const [isFetching, setIsFetching] = useState(false); // État de chargement des suggestions
  const debounceTimeout = useRef(null); // Pour le debounce

  const [isFormValid, setIsFormValid] = useState(false);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('generale');
  const [token, setToken] = useState(localStorage.getItem('jwt') || '');
  const [clicksCount, setClicksCount] = useState(0);
  const [ip, setIp] = useState('');
  const ipRef = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [showAd, setShowAd] = useState(false); // Ajouté pour contrôler l'affichage de la vidéo publicitaire
  const [videoCompleted, setVideoCompleted] = useState(false); // Gérer la fin de la publicité
  const [themeGenerated, setThemeGenerated] = useState(false); // Gérer la fin de la génération du thème
  const [adFade, setAdFade] = useState(''); // Gérer les classes de fade-in / fade-out
  const [themeContent, setThemeContent] = useState(''); // Pour stocker le thème généré
  const [randomImage, setRandomImage] = useState(''); // État pour stocker l'image aléatoire

  const [email, setEmail] = useState(''); // Gestion de l'adresse email
  const [emailError, setEmailError] = useState(''); // Gestion de l'erreur de format d'email

  const [isWindowActive, setIsWindowActive] = useState(true); // Pour détecter si la fenêtre est active
  const toastQueue = useRef([]); // File d'attente pour les messages de toast
  const maxToasts = 3; // Limiter à 3 toasts affichés en même temps
  const activeToasts = useRef(new Set()); // Ensemble des IDs de toasts actifs


  const apiHost = process.env.REACT_APP_API_HOST || 'http://localhost:5000'; // Utilise une valeur par défaut si la variable n'existe pas
  const socketRef = useRef(null); // Utilisation de useRef pour gérer la WebSocket
  const decryptionKey = CryptoJS.enc.Hex.parse(
    CryptoJS.SHA256(process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );

  // Référence pour la div contenant le contenu
  const contentRef = useRef(null);
/*
  const geocoder = new GeocoderGeonames({
    username: process.env.REACT_APP_GEOCODER_ID, // Remplacez par votre nom d'utilisateur GeoNames
  });*/

    // Fonction de gestion de la saisie dans le champ ville
    const handleCityInputChange = (e) => {
      const cityName = e.target.value;
      setFormData((prev) => ({ ...prev, villeNaissance: cityName }));
    
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current); 
      }
    
      // Si le champ est vide, réinitialiser les données
      if (!cityName.trim()) {
        setSuggestions([]);
        setFormData((prev) => ({ ...prev, paysNaissance: '' }));
        return;
      }
    
      // Limiter les appels API avec debounce
      debounceTimeout.current = setTimeout(() => {
        if (cityName.length > 2) {
          fetchCitySuggestions(cityName);
        } else {
          setSuggestions([]); // Vider les suggestions si la saisie est trop courte
        }
      }, 500); // Délai de 500ms pour limiter les requêtes
    };

  // Fonction pour récupérer les suggestions d'autocomplétion
  const fetchCitySuggestions = async (query) => {
    setIsFetching(true);
    try {
      const response = await axios.get(`${apiHost}/api/geonames`, {
        params: {
          q: query,
          maxRows: 8,
          lang: 'fr',
        },
      });

      // Formater les suggestions avec ville et pays
      const formattedSuggestions = response.data.geonames.map((location) => ({
        city: location.name,
        country: location.countryName,
        countryCode: location.countryCode, // Utilisé pour les drapeaux si nécessaire
      }));

      setSuggestions(formattedSuggestions);
    } catch (error) {
      console.error('Erreur lors de la récupération des suggestions :', error);
    } finally {
      setIsFetching(false);
    }
  };

  // Fonction appelée lorsqu'une suggestion est sélectionnée
  const handleSuggestionSelect = (suggestion) => {
    setFormData((prev) => ({
      ...prev,
      villeNaissance: suggestion.city,
      paysNaissance: suggestion.country,
    }));
    setSuggestions([]); // Vider les suggestions après la sélection
  };
  
  // Tableau des chemins des images
  const imagePaths = [
    { image: '/images/signs/aquarius.gif',speech: "Michael Jordan est Verseau. Un génie du dunk, mais il pourrait aussi vous battre au poker, juste pour le sport. Verseau = innovant, compétitif… et toujours au-dessus (littéralement)."},
    { image: '/images/signs/aries.gif',speech: "Denzel Washington est Bélier. Avec sa détermination, il pourrait convaincre n’importe qui de rejoindre une croisade… même sans épée. Bélier = leader né, passionné… mais parfois il fonce avant de réfléchir !"},
    { image: '/images/signs/cancer.gif',speech: "Amélie Mauresmo est Cancer. Sensible et protectrice, elle pourrait vous remonter le moral après vous avoir battu 6-0. Cancer = émotif, mais quand il s’agit de défendre ses proches, c’est une forteresse imprenable."},
    { image: '/images/signs/capricorn.gif',speech: "Bradley Cooper est Capricorne. Toujours concentré et ambitieux, il pourrait jouer un chef étoilé dans A Star is Born… et décrocher une étoile Michelin en vrai. Capricorne = perfectionniste, intense… mais toujours élégant."},
    { image: '/images/signs/gemini.gif',speech: "Jon Jones est Gémeaux. Avec sa double personnalité, il peut vous mettre K.O. dans l’octogone et vous charmer avec un discours après. Gémeaux = adaptable, communicatif… parfois trop rapide à passer à l’action."},
    { image: '/images/signs/leo.gif',speech: "J-Lo est Lion. Elle brille tellement qu’on pourrait dire qu’elle a volé la place du soleil. Lion = charismatique, un peu diva… mais bon, on adore les regarder rugir."},
    { image: '/images/signs/libra.gif',speech: "Will Smith est Balance. Équilibré et charmant, il pourrait même réconcilier deux chats qui se battent pour une gamelle. Balance = pacifiste, sociable, mais attention, il peut aussi gifler en cas de déséquilibre !"},
    { image: '/images/signs/pisces.gif',speech: "Steve Jobs était Poissons. Visionnaire et perfectionniste, il a rêvé de mondes numériques… et les a rendus réels. Poissons = idéaliste, inventif… mais parfois un peu trop exigeant avec la réalité."},
    { image: '/images/signs/sagittarius.gif',speech: "Kylian Mbappé est Sagittaire. Toujours prêt à partir à l’aventure… ou à traverser le terrain à la vitesse de la lumière. Sagittaire = optimiste, aventureux… mais parfois un peu trop tête brûlée."},
    { image: '/images/signs/scorpio.gif',speech: "Voltaire était Scorpion. Avec sa plume acérée, il pouvait démolir un adversaire tout en restant poli. Scorpion = mordant, brillant… et toujours prêt à combattre pour ses idéaux."},
    { image: '/images/signs/taurus.gif',speech: "Zinédine Zidane est Taureau. Calme et posé, mais attention : s’il charge, même Materazzi peut témoigner. Taureau = persévérant, fidèle… mais gare aux coups de tête (dans tous les sens)."},
    { image: '/images/signs/virgo.gif',speech: "Beyoncé est Vierge. Perfectionniste à un tel niveau qu’elle pourrait danser sur “Single Ladies” et faire son bilan comptable en même temps. Vierge = organisée, méticuleuse… un peu maniaque, mais toujours en beauté."},
  ];

  // Fonction pour sélectionner une image aléatoire
  const selectRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * imagePaths.length);
    return imagePaths[randomIndex];
  };
  
  // Déclenché à chaque activation du chargement
  useEffect(() => {
    if (loading) {
      setRandomImage(selectRandomImage());
    }
  }, [loading]);

  // Liste des données aléatoires
  const randomData = [
    { prenom: 'Alice', signe: 'Bélier', ascendant: 'Lion' },
    { prenom: 'Maxime', signe: 'Scorpion', ascendant: 'Verseau' },
    { prenom: 'Chloé', signe: 'Vierge', ascendant: 'Cancer' },
    { prenom: 'Lucas', signe: 'Sagittaire', ascendant: 'Poissons' },
    { prenom: 'Emma', signe: 'Taureau', ascendant: 'Balance' },
    { prenom: 'Paul', signe: 'Capricorne', ascendant: 'Gémeaux' },
    { prenom: 'Salima', signe: 'Capricorne', ascendant: 'Taureau' },
    { prenom: 'Georgette', signe: 'Verseau', ascendant: 'Sagitaire' },
    { prenom: 'Guillaume', signe: 'Cancer', ascendant: 'Cancer' },
    { prenom: 'Joseph', signe: 'Poissons', ascendant: 'Vierge' }, 
    { prenom: 'Stéphanie', signe: 'Cancer', ascendant: 'Capricorne' }, 
    { prenom: 'Sabrina', signe: 'Lion', ascendant: 'Balance' }, 
    { prenom: 'Adonis', signe: 'Bélier', ascendant: 'Scorpion' } 
  ];

  // Fonction pour déchiffrer les données
  const decryptData = (encryptedData, iv) => {
    try {
      // Décodage de l'IV depuis base64
      const decodedIV = CryptoJS.enc.Base64.parse(iv);

      // Déchiffrement des données
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, decryptionKey, {
        iv: decodedIV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Convertir en texte UTF-8
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

      return JSON.parse(decryptedText); // Convertir en JSON
    } catch (error) {
      console.error("Erreur de déchiffrement :", error);
      throw new Error("Échec du déchiffrement des données.");
    }
  };

  // Fonction pour récupérer un prénom aléatoire depuis le backend
  const fetchRandomName = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/toast-info`);
      
      const { encryptedData, iv } = response.data; // Récupérer les données chiffrées et l'IV

      // Déchiffrer les données
      const decryptedData = decryptData(encryptedData, iv); 

      return decryptedData.prenom; // Retourne le prénom déchiffré
    } catch (error) {
      console.error('Erreur lors de la récupération du toast :', error);
      return 'Utilisateur'; // Valeur par défaut en cas d'erreur
    }
  };

  // Fonction pour générer dynamiquement les données pour le toast
  const generateDynamicData = async () => {
    const prenom = await fetchRandomName();
    const signes = ['Bélier', 'Taureau', 'Gémeaux', 'Cancer', 'Lion', 'Vierge', 'Balance', 'Scorpion', 'Sagittaire', 'Capricorne', 'Verseau', 'Poissons'];
    const ascendants = ['Lion', 'Verseau', 'Cancer', 'Balance', 'Poissons', 'Gémeaux', 'Capricorne', 'Scorpion', 'Taureau', 'Vierge', 'Sagittaire', 'Bélier'];

    const randomSigne = signes[Math.floor(Math.random() * signes.length)];
    const randomAscendant = ascendants[Math.floor(Math.random() * ascendants.length)];

    return {
      prenom,
      signe: randomSigne,
      ascendant: randomAscendant,
    };
  };

    // Fonction pour générer un toast si la fenêtre est active
    const showToast = async () => {
      if (toastQueue.current.length > 0 && activeToasts.current.size < maxToasts) {
        const message = toastQueue.current.shift(); // Retirer le message en tête de la file
        const toastId = toast(message, {
          position: "bottom-right",
          className:"bg-gradient-primary-toast",
          autoClose: 5000,
          onClose: () => {
            activeToasts.current.delete(toastId); // Retirer le toast actif à sa fermeture
          },
        });
        activeToasts.current.add(toastId);
      }
    };
  
    // Fonction pour ajouter un toast à la file d'attente
    const enqueueToast = async (message) => {
      toastQueue.current.push(message);
      if (isWindowActive) {
        await showToast(); // Afficher immédiatement si possible
      }
    };

  // Fonction pour générer un message aléatoire
  const generateRandomToastMessage = async () => {
    const data = await generateDynamicData();
    return `${data.prenom} vient de découvrir son thème astral ${data.signe} ascendant ${data.ascendant}. Pourquoi pas vous ?`;
  };

  // Intervalle pour ajouter des messages à la file d'attente
  useEffect(() => {
    const interval = setInterval(async () => {
      if (isWindowActive) {
        const message = await generateRandomToastMessage();
        enqueueToast(message);
      }
    }, 15000); // Intervalle de 15 secondes

    return () => clearInterval(interval); // Nettoyage à la fin
  }, [isWindowActive]);

  // Gestion de la visibilité de la fenêtre
  useEffect(() => {
    const handleVisibilityChange = async () => {
      setIsWindowActive(!document.hidden);
      if (!document.hidden) {
        while (toastQueue.current.length > 0 && activeToasts.current.size < maxToasts) {
          await showToast(); // Afficher les toasts en file d'attente si la fenêtre redevient active
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Fonction pour vérifier si le token est expiré
  const isTokenExpired = (token) => {
    if (!token) return true;

    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      return tokenPayload.exp < currentTime;
    } catch (error) {
      console.error('Erreur lors de la vérification du token :', error);
      return true;
    }
  };

  // Fonction pour vérifier la santé du serveur
  const checkServerStatus = async () => {
    try {
      const res = await axios.get(`${apiHost}/health-check`); // Route de santé du serveur
      return res.status === 200;
    } catch (error) {
      console.error('Le serveur est hors ligne', error);
      return false;
    }
  };

  // Fonction pour récupérer le token
  const fetchToken = useCallback(async () => {
    try {
      const res = await axios.get(`${apiHost}/api/token`);
      const newToken = res.data.token;
      setToken(newToken);
      localStorage.setItem('jwt', newToken);
    } catch (error) {
      console.error('Erreur lors de la récupération du token', error);
    }
  }, [apiHost]);

  
  const checkAndRefreshToken = useCallback(async () => {
    if (!token || isTokenExpired(token)) {
      console.log('Token expiré ou non disponible. Rafraîchissement en cours...');
      await fetchToken(); // Récupérer un nouveau token
    }
  }, [token, fetchToken]);
  

    // Fonction de validation de l'email
    const validateEmail = (email) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
    };

    const handleEmailChange = (e) => {
      const value = e.target.value;
      setEmail(value);

      if (!validateEmail(value)) {
        setEmailError('Le format de l\'adresse email est incorrect.');
      } else {
        setEmailError('');
      }
    };

    // Calcul du temps restant avant la réinitialisation
    const calculateTimeRemaining = (lastClickTime) => {
      const currentTime = Date.now();
      const timeSinceLastClick = currentTime - lastClickTime;
      const timeLeft = 24 * 60 * 60 * 1000 - timeSinceLastClick; // 24 heures en millisecondes
      return timeLeft > 0 ? timeLeft : 0;
    };

    // Fonction pour incrémenter le compteur de clics
    const incrementClicksCount = () => {

      if (!ipRef.current) {
        console.warn('IP non définie. Increment des clics annulé.');
        return;
      }
      const currentTime = Date.now();
      let storedData = JSON.parse(localStorage.getItem(ipRef.current)) || { clicks: 0, lastClick: 0 };

      const timeSinceLastClick = currentTime - storedData.lastClick;

      // Si plus de 24h se sont écoulées, on réinitialise le compteur
      if (timeSinceLastClick >= 24 * 60 * 60 * 1000) {
        storedData = { clicks: 1, lastClick: currentTime }; // Réinitialiser après 24h
      } else {
        storedData.clicks += 1;
      }

      storedData.lastClick = currentTime;
      localStorage.setItem(ipRef.current, JSON.stringify(storedData));

      setClicksCount(storedData.clicks);
      setTimeRemaining(calculateTimeRemaining(storedData.lastClick)); // Calcul du temps restant
    };

  
    // Fonction de déconnexion propre
    const disconnectSocket = useCallback(() => {
      if (socketRef.current && socketRef.current.connected) {
        console.log('Déconnexion de la WebSocket avec l\'ID:', socketRef.current.id);
        socketRef.current.disconnect();
        socketRef.current = null; // Libérer la WebSocket après la déconnexion
      } else {
        console.log("Aucune WebSocket active à déconnecter.");
      }
    }, []);

    const initializeWebSocket = useCallback(() => {
      return new Promise((resolve, reject) => {
        if (!socketRef.current || !socketRef.current.connected) {
          console.log('Création d\'une nouvelle WebSocket.');
          const newSocket = io(apiHost, {
            reconnection: false,
            timeout: 10000, // Timeout de 10 secondes
          });
          socketRef.current = newSocket;
    
          newSocket.on('connect', () => {
            console.log('Connexion WebSocket établie avec l\'ID:', newSocket.id);
            resolve(newSocket.id);  // La promesse est résolue une fois que la WebSocket est connectée
          });
    
          newSocket.on('generationComplete', (data) => {
            setResponse(data.message);
            setThemeContent(data.message);
            setThemeGenerated(true);
            incrementClicksCount();
            setLoading(false);
    
            // Déconnecter après avoir traité la tâche
            disconnectSocket();
            console.log('Connexion WebSocket fermée après génération.');
          });
          
          newSocket.on('generationError', (data) => {
            console.error('Erreur reçue depuis le serveur:', data.error);
            setLoading(false); // Arrête le loader
            toast.error(data.error);
          });

          newSocket.on('disconnect', (reason) => {
            console.log('Déconnecté du serveur WebSocket :', reason);
          });
    
          newSocket.on('connect_error', (error) => {
            console.error('Erreur de connexion WebSocket:', error);
            reject(error);  // La promesse est rejetée si la connexion échoue
          });
        } else {
          console.log('WebSocket déjà connectée avec l\'ID:', socketRef.current.id);
          resolve(socketRef.current.id);  // Si la WebSocket est déjà connectée, on résout immédiatement la promesse
        }
      });
    }, [apiHost, disconnectSocket]);
    
    useEffect(() => {
      initializeWebSocket();
    return () => {
      if (socketRef.current) {
        console.log('Déconnexion de la WebSocket avec l\'ID:', socketRef.current.id);
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [initializeWebSocket]); 

  // Appel backend API pour générer le thème via WebSocket
  const callBackendAPI = async (data) => {
    try {

      await checkAndRefreshToken();
      // Attendre que la WebSocket soit connectée et obtenir l'ID
      const socketId = await initializeWebSocket();

      setLoading(true);
      setResponse('');
      //setShowAd(true); // Afficher la publicité vidéo
      setThemeGenerated(false); // Réinitialiser l'état de génération
      //setAdFade('fade-in'); // Lancer le fade-in

      const config = {
        headers: {
          'x-api-key': process.env.REACT_APP_CLIENT_API_KEY,
          'Authorization': `Bearer ${token}`,
          'x-socket-id': socketRef.current?.id,
        },
      };

      const res = await axios.post(`${apiHost}/api/generate`, data, config); // Envoi du formulaire au backend

      if (res.status === 503) {
        setResponse('Le service est temporairement indisponible. Veuillez réessayer plus tard.');
        setShowAd(false); // Masquer la publicité
      }
    } catch (error) {
      console.error('Erreur lors de l’appel au backend', error);
      setResponse('Erreur lors de la génération de votre thème astral. Veuillez réessayer.');
      setLoading(false);
      setShowAd(false); // Masquer la vidéo en cas d'erreur
    }
  };

  const handleSendEmail = async () => {
    if (clicksCount >= 2) return;

    if (!themeContent) {
      toast.error('Le thème n\'a pas encore été généré.');
      return;
    }

    try {
      const response = await axios.post(`${apiHost}/api/send-email`, {
        email: email,
        themeContent: marked(themeContent), // Utiliser themeContent ici
      });

      if (response.status === 200) {
        toast.success('Le thème a été envoyé par email avec succès!');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email :', error);
      toast.error('Une erreur est survenue lors de l\'envoi de l\'email.');
    }
  };

  // Gestion de la vidéo publicitaire et de la soumission du formulaire
  const handleAdEnd = () => {
    setVideoCompleted(true); // Marquer la publicité comme terminée
    handleAdClose(); // Fermer la vidéo si la génération est terminée
  };

  // Fermer la publicité avec fade-out
  const handleAdClose = () => {
    setAdFade('fade-out'); // Lancer le fade-out
    setTimeout(() => {
      setShowAd(false);
      setAdFade(''); // Réinitialiser l'animation
    }, 1000); // 1 seconde pour le fade-out
  };

  // useEffect pour gérer le compteur de clics et le temps restant
  useEffect(() => {
    if (clicksCount >= 2) {
      const storedData = JSON.parse(localStorage.getItem(ipRef.current)) || { clicks: 0, lastClick: 0 };
      const timeLeft = calculateTimeRemaining(storedData.lastClick);

      setTimeRemaining(timeLeft); // Met à jour le temps restant

      const interval = setInterval(() => {
        const updatedTimeLeft = calculateTimeRemaining(storedData.lastClick);
        setTimeRemaining(updatedTimeLeft);

        if (updatedTimeLeft <= 0) {
          clearInterval(interval); // Arrête l'intervalle une fois que le délai est expiré
          setErrorMessage('');
          setClicksCount(0); // Réinitialiser après 24h
        }
      }, 1000); // Mettre à jour toutes les secondes

      return () => clearInterval(interval);
    }
  }, [clicksCount, ip]);

  // Affichage dynamique du message d'erreur
  useEffect(() => {
    if (clicksCount >= 2 && timeRemaining > 0) {
      const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      setErrorMessage(`Le nombre maximum de consultations est atteint pour aujourd'hui. Veuillez réessayer dans ${hours} heures et ${minutes} minutes.`);
    }
  }, [clicksCount,timeRemaining]);

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    if (clicksCount >= 2) return;

    const payload = {
      themeName: selectedTheme,
      formData: formData
    };

    callBackendAPI(payload);
  };
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const res = await axios.get(`${apiHost}/api/get-ip`);
        setIp(res.data.ip);
        ipRef.current = res.data.ip; // Stocker l'IP dans un ref
        //console.log("Set IP",res.data.ip);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'adresse IP', error);
      }
    };
  
    fetchIp();
  }, []); // Ce `useEffect` se déclenche au montage du composant, une seule fois.

  // useEffect pour vérifier le token et récupérer l'adresse IP
  useEffect(() => {
    const initializeApp = async () => {
      const serverOnline = await checkServerStatus();
      if (serverOnline) {
        if (!token || isTokenExpired(token)) fetchToken();
        // Toujours récupérer l'IP avant de vérifier les clics
        try {
          if (ipRef.current) {
            //console.log("IP récupérée :",ipRef.current);
            // Charger les clics depuis le localStorage avec l'IP
            const storedData = JSON.parse(localStorage.getItem(ipRef.current)) || { clicks: 0, lastClick: 0 };
            setClicksCount(storedData.clicks);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'adresse IP', error);
        }
      }
    };

    initializeApp();
  }, [fetchToken,ip]);//[token, fetchToken, apiHost, ip, checkAndRefreshToken]);

  useEffect(() => {
    validateForm();
  }, [formData]); // Exécute la validation à chaque mise à jour de formData
  
  // Gestion des changements dans le formulaire
  const handleChange = (e) => {

    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateForm();
  };

  // Validation du formulaire
  const validateForm = () => {
    const { prenom, dateNaissance, heureNaissance, villeNaissance, paysNaissance } = formData;
    if (prenom && dateNaissance && heureNaissance && villeNaissance && paysNaissance) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  // Fonction pour récupérer le contenu formaté
  const getFormattedContentFromDiv = () => {
    if (contentRef.current) {
      let rawHtml = contentRef.current.innerHTML;
      // Mettre tout ce qui est entre <h3> en gras
      rawHtml = rawHtml.replace(/<h3>(.*?)<\/h3>/g, (_, content) => `\n*${content.trim()}*\n`);
  
      // Mettre tout ce qui est entre <strong> en gras
      rawHtml = rawHtml.replace(/<strong>(.*?)<\/strong>/g, (_, content) => `\n*${content.trim()}*\n`);
  
      // Remplacer les <br> par des retours à la ligne
      rawHtml = rawHtml.replace(/<br\s*\/?>/g, '\n\n');
  
      // Supprimer les autres balises HTML pour ne garder que du texte brut
      const plainText = rawHtml.replace(/<[^>]+>/g, '').trim() +"\n\n Propulsé avec amour <3   par www.astrothemepro.com";
      console.log("PLAIN TEXT:",plainText)
      return plainText; // Retourne le texte formaté pour WhatsApp
    }
    return '';
  };

  const handleShareOnWhatsApp = () => {
    const content = getFormattedContentFromDiv(); // Récupérer le contenu de la div
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(content)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (



  <div className="main-container">
      <ToastContainer />
      {/* Conteneur de la vidéo publicitaire */}
      {showAd && (
        <div className={`ad-container ${adFade}`}>
          <video
            id="ad-video"
            autoPlay
            onEnded={handleAdEnd} // Appeler handleAdEnd quand la vidéo se termine
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          >
            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
            Votre navigateur ne supporte pas la balise vidéo.
          </video>
        </div>
      )}
      {/* Formulaire et résultats */}
      {!showAd && (
          <div>
            <header>

                <div className='page-header min-vh-75 relative'>
                <div className="container">
                <div className="row addMargin">
                <div className="col-12 ">
                <h1 className="text-center text-white pt-3 mt-n5"><span className="highlight">Votre Thème Astral Personnalisé</span></h1>
                <p className="text-center lead text-white mt-3">Simple et rapide, vous en apprendrez plus sur votre caractère. </p>
          </div>
          </div>
                    </div>
                  <span className="mask bg-gradient-primary"></span>

                  <div className="wave-container ">
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                      <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                      </defs>
                      <g className="moving-waves">
                        <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)"></use>
                        <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
                        <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
                        <use href="#gentle-wave" x="48" y="7" fill="rgba(255,255,255,0.2)"></use>
                        <use href="#gentle-wave" x="48" y="9" fill="rgba(255,255,255,0.1)"></use>
                        <use href="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                      </g>
                    </svg>
                  </div>
                </div> 
              </header>

              <section id="main" className="my-5 py-5">
                <div className="container">

                  <div className="row">
                    <div className="col-7 mx-auto blur z-index-3 shadow-blur border-radius-xl pdz-mt-n10 pdz-mx-auto pdz-py-3">
                      <div className="form-section">
                      <div className="container ">
                  
                      <div className="form-group " >
                        <label>Sélectionnez un domaine dans la liste:</label>
                        <select
                          className="form-control"
                          value={selectedTheme}
                          onChange={(e) => setSelectedTheme(e.target.value)}
                        >
                          <option value="generale">Théme astral</option>
                          <option value="horoscope">Horoscope du jour</option>
                        </select>
                      </div>

                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          <div className="form-group">
                            <label><span className="required-star">*</span> Prénom</label>
                            <input type="text" className="form-control" name="prenom" onChange={handleChange} required />
                          </div>
                          <div className="form-group">
                            <label><span className="required-star">*</span> Date de Naissance</label>
                            <input type="date" className="form-control" name="dateNaissance" onChange={handleChange} required />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group">
                            <label><span className="required-star">*</span> Heure de Naissance</label>
                            <input type="time" className="form-control" name="heureNaissance" onChange={handleChange} required />
                          </div>
                          <div className="form-group">
                            <label><span className="required-star">*</span> Ville de Naissance</label>
                            <input type="text" className="form-control" name="villeNaissance" value={formData.villeNaissance} onChange={handleCityInputChange} onFocus={handleChange} onBlur={handleChange}  onKeyUp={handleChange} required />
                            {isFetching && <small>Chargement...</small>}
                            {!isFetching &&
                              formData.villeNaissance.trim() && 
                              suggestions.length === 0 && 
                              formData.paysNaissance === '' && ( // Vérifie que le pays n'est pas encore défini
                              <ul className="suggestions-list">
                                <li className="suggestion-item">Aucun résultat trouvé.</li>
                              </ul>
                            )}
                              {suggestions.length > 0 && (
                                <ul className="suggestions-list">
                                  {suggestions.map((suggestion, index) => (
                                    <li
                                      key={index}
                                      onClick={() => handleSuggestionSelect(suggestion)}
                                      className="suggestion-item"
                                    >
                                      {suggestion.city}, {suggestion.country}{' '}
                                      {suggestion.countryCode && (
                                        <img
                                          src={`https://flagcdn.com/w40/${suggestion.countryCode.toLowerCase()}.png`}
                                          alt={suggestion.country}
                                          className="country-flag"
                                        />
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group ">
                            <label><span className="required-star">*</span> Pays de Naissance</label>
                            <input type="text" className="form-control" name="paysNaissance"  value={formData.paysNaissance} onChange={handleChange}  onFocus={handleChange} onBlur={handleChange}  onKeyUp={handleChange} readOnly />
                          </div>
                        </div>
                        <div className="required-info text-end mb-3"><small><span className="required-star">*</span> Champs obligatoires</small></div>

                        {/* Affichage du message d'erreur si le nombre de clics est atteint */}
                        {errorMessage && (
                          <div className="alert alert-danger text-center" role="alert">
                            {errorMessage}
                          </div>
                        )}
                        {/* Bouton désactivé pendant le chargement */}
                        <button type="submit" className="btn btn-primary w-100" disabled={!isFormValid || loading}>
                          {loading ? 'Génération en cours...' : 'Générer Mon Rapport'}
                        </button>
                      </form>
                      </div>
                    </div>
                  {/* Formulaire d'envoi du thème par email après la génération */}
                  {themeGenerated && (
                    
                      <div className="email-section mt-5">
                        <label htmlFor="email">Recevoir le thème par email <b>(<s>1€</s> Gratuit)</b></label>
                        <div className="form-group row">
                          {/* Pour les grandes résolutions, les éléments seront sur une seule ligne */}
                          <div className="col-md-8 col-12 mb-2">
                            <input
                              type="email"
                              className="form-control w-100"
                              id="email"
                              value={email}
                              onChange={handleEmailChange}
                              placeholder="Entrez votre adresse email"
                              required
                            />
                            {emailError && <small className="text-danger">{emailError}</small>}
                          </div>

                          <div className="col-md-4 col-12">
                            <button
                              className="btn btn-secondary w-100"
                              onClick={handleSendEmail}
                              disabled={!email || emailError !== ''}
                            >
                              Envoyer par email
                            </button>
                          </div>
                        </div>
                      </div>
                  )}
                    <hr className="horizontal dark mb-5"></hr>
                  <div className="mt-4">
                    {/* Affichage du loader ou du texte */}
                    {loading ? (
                      <div className='loader-container'>
                        <div className="loader"></div> 
                        <img
                          src={randomImage.image}
                          alt="Signe astrologique"
                          className="random-sign-image"
                          style={{ width: '15rem' }}
                        />
                        <div><p className="text-gradient text-primary mb- bold">Le saviez-vous ?  </p><p className='text-light-black'>{randomImage.speech}</p></div>
                      </div>
                    ) : (
                      <div>
                        <div
                          className="form-control result-container no-copy"
                          style={{ height: 'auto', padding: '15px', border: '0px solid #ddd', borderRadius: '6px' }}
                          ref={contentRef} // Associer la référence ici
                          dangerouslySetInnerHTML={{ __html: marked(response) }} // Utilisation de `marked` pour le formatage
                        />
                  
                      </div>
                    )}
                    {themeGenerated && (
                        <div className="share-section mt-5">
                          <label><b>Partagez votre rapport avec vos proches </b></label>
                          <div className="d-flex justify-content-center mt-3">
                          <button
                              className="btn btn-success d-flex align-items-center"
                              onClick={() => handleShareOnWhatsApp(themeContent)}
                            >
                              <FontAwesomeIcon icon={faWhatsapp} className="me-2" />
                              Partager sur WhatsApp
                            </button>
                          </div>
                        </div>
                        )}
                    </div>
                    </div>
                  </div>
                </div>
              </section>    
    </div>
      )}
</div>
  );
}

export default App;